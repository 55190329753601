import React from 'react';
import PropTypes from 'prop-types';
import { getFunName } from '../helpers';
class StorePicker extends React.Component {
  myInput = React.createRef();

  static propTypes = {
    history: PropTypes.object,
  };

  goToStore = (e) => {
    e.preventDefault();
    //get the text from that input

    const storeName = this.myInput.current.value;

    //change the page to /store/whatever-they-entered

    this.props.history.push(`/store/${storeName}`);
  };

  render() {
    return (
      <>
        <form className="store-selector" onSubmit={this.goToStore}>
          <h2>Please enter a store</h2>
          <button onClick={this.handleClick}>Click</button>
          <input
            type="text"
            ref={this.myInput}
            required
            placeholder="Store Name"
            defaultValue={getFunName()}
          />
          <button type="submit">Visit Store</button>
        </form>
      </>
    );
  }
}

export default StorePicker;
