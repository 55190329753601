import Rebase from 're-base';
import firebase from 'firebase';

// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/database';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyB_aboUss95bhEWCjI8_mxymqQSERbVXu8',
  authDomain: 'catch-of-the-day-jlai.firebaseapp.com',
  databaseURL: 'https://catch-of-the-day-jlai-default-rtdb.firebaseio.com',
});

const base = Rebase.createClass(firebaseApp.database());

//this is a named export
export { firebaseApp };

//deafault export
export default base;
